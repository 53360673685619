import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import ScrollPointer from './ScrollPointer';

export default class StoryBlockScrollshot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.scrollImageRef = null;
    this.scrollContainer = null;
    this.isMouseDown = false;
    this.mouseY = null;
    this.currentY = null;
    this.imageComputedStyle = null;
    this.clampY = null;
  }

  componentDidMount() {
    this.scrollContainer.addEventListener('mousedown', this.mousedown);
    this.scrollContainer.addEventListener('mousemove', this.mousemove);
  }

  componentWillUnmount() {}

  mousedown = e => {
    // console.log('mouse down!');
    this.isMouseDown = true;
    this.mouseY = e.pageY;
    this.scrollContainer.addEventListener('mouseup', this.mouseup);

    let currentTransform = parseFloat(this.scrollImageRef.style.transform.split('(')[1]);
    this.currentY = currentTransform ? currentTransform : 0;

    this.imageComputedStyle = window.getComputedStyle(this.scrollImageRef);
    // console.log(this.imageComputedStyle);
    this.clampY =
      window.innerHeight -
      parseFloat(this.imageComputedStyle.marginBottom) -
      (parseFloat(this.imageComputedStyle.marginTop) + parseFloat(this.imageComputedStyle.height));
    // alert('clampY: ' + this.clampY);
  };

  mousemove = e => {
    // console.log('mouse mooooooooove!');
    if (this.isMouseDown) {
      // console.log('rawvalue', this.scrollImageRef.style.transform);
      // console.log('rawvalue', this.scrollImageRef.style.transform.split('(')[1]);
      // console.log('currentTransform', currentTransform);
      // console.log((currentTransform ? currentTransform : 0) + e.pageY - this.mouseY);
      let movementFactor = this.currentY + e.pageY - this.mouseY;
      if (movementFactor > 0) {
        movementFactor = 0;
      }
      if (movementFactor < this.clampY) {
        movementFactor = this.clampY;
      }

      let transformPosition = movementFactor;
      this.scrollImageRef.style.transform = `translateY(${transformPosition}px`;
    }
  };

  mouseup = e => {
    // console.log('mouse UP!');
    this.isMouseDown = false;
    this.mouseY = null;
    this.scrollContainer.removeEventListener('mouseup', this.mouseup);
  };

  render() {
    return (
      <div className="story__scrollshot">
        <div className="story__scrollshot__container" ref={div => (this.scrollContainer = div)}>
          <ScrollPointer
            identifier={this.props.identifier}
            scrollContainer={this.scrollContainer ? this.scrollContainer : null}
            canvasBoundingBox={this.scrollImageRef ? this.scrollImageRef.getBoundingClientRect() : null}
            darkCursor={this.props.darkCursor}
          />
          <img
            className="story__scrollshot__container__content"
            src={this.props.src}
            alt={this.props.alt}
            ref={img => (this.scrollImageRef = img)}
          />
        </div>
      </div>
    );
  }
}
