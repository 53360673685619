import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, CSSPlugin } from 'gsap/all';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from '../components/SplitParagraph';
import AnimateText from '../components/AnimateText';

import ProjectView from '../components/ProjectView';

export default class ComingSoon extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isCaseStudy: false };

    this.originalPath = '';

    this.container = null;
  }

  static contextTypes = Constants.contextTypesRouterOnly;

  componentWillMount() {}

  componentDidMount() {
    // window.onpopstate = function() { };
  }

  updateProjectsOpacity = (value, time) => {
    // console.log('update opc', value);
    TweenMax.to(this.container, time, { ease: 'Mo', opacity: value });
  };

  render() {
    return (
      <section className="coming-soon" ref={section => (this.container = section)}>
        <div className="coming-soon__title">
          <h1 className="coming-soon__title__content">Coming Soon</h1>
        </div>
        <div className="coming-soon__container">
          <div className="coming-soon__container__content">
            <div className="coming-soon__projects">
              <h2 className="coming-soon__projects__title">Projects</h2>
              <p className="coming-soon__projects__description">
                Projects that are incomplete or a work-in-progress.
              </p>
              <div className="coming-soon__projects__project">
                <h3 className="coming-soon__projects__project__title">Notion Icons</h3>
                <p className="coming-soon__projects__project__description">
                  (Unofficial) Use custom icon sets directly from Notion. Usable in Google Chrome, Notion app
                  on Windows and macOS.
                </p>
                <p className="coming-soon__projects__project__link">
                  <a
                    href="https://www.notion.so/Notion-Icons-41b7ad2dcb594c569e0d75d0b68ded00"
                    target="_blank"
                  >
                    View Project on Notion
                  </a>
                </p>
              </div>
              <div className="coming-soon__projects__project">
                <h3 className="coming-soon__projects__project__title">Koreans Who</h3>
                <p className="coming-soon__projects__project__description">
                  Korean Who celebrates all Korean Designers and Developers, including the accomplished and
                  the new. As a directory, we aim to inspire the next generation in tech and create a sense of
                  belonging and community.
                </p>
                <p className="coming-soon__projects__project__link">
                  <a
                    href="https://www.notion.so/Koreans-Who-bb3d0362f9cd48f18411daf50f92b767"
                    target="_blank"
                  >
                    View Project on Notion
                  </a>
                </p>
              </div>
            </div>
            <div className="coming-soon__writings">
              <h2 className="coming-soon__writings__title">Writings</h2>
              <p className="coming-soon__projects__description">
                Documentation of experimentation, experience, and ideas. Mostly drafts as of now.
              </p>
              <div className="coming-soon__projects__project">
                <h3 className="coming-soon__projects__project__title">Redoing The Responsive Web</h3>
                <p className="coming-soon__projects__project__description">
                  Thoughts on new, better ways to build responsive websites.
                </p>
                <p className="coming-soon__projects__project__link">
                  <a
                    href="https://medium.com/@jayhxmo/redoing-the-responsive-web-afa587ff1ac5"
                    target="_blank"
                  >
                    Read Draft
                  </a>
                </p>
              </div>
              <div className="coming-soon__projects__project">
                <h3 className="coming-soon__projects__project__title">
                  How To Export Motion Case Studies From After Effects
                </h3>
                <p className="coming-soon__projects__project__link">
                  <a
                    href="https://medium.com/@jayhxmo/how-to-export-motion-case-studies-from-after-effects-97a6cdf254b6"
                    target="_blank"
                  >
                    Read Draft
                  </a>
                </p>
              </div>
              <div className="coming-soon__projects__project">
                <h3 className="coming-soon__projects__project__title">
                  My Journey of Becoming a Better Designer (2015)
                </h3>
                <p className="coming-soon__projects__project__link">
                  <a
                    href="https://medium.com/@jayhxmo/my-journey-of-becoming-a-better-designer-fa7547151061"
                    target="_blank"
                  >
                    Read on Medium
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
