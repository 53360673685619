import * as ProjectsData from './ProjectsData';

// Bezier.quadraticFromPoints({x: 0.64, y: 0}, {x: 0.04, y: 0}, {x: 0.35, y: 0}, 1);

// function bezY(x1) {
// 	let x = x1 + 0.373;
// 	let firstSeg = 0.64 * (1 - x) * (1 - x) * (1 - x);
// 	let secondSeg = 3 * 0.04 * ((1 - x) * (1 - x)) * x;
// 	let thirdSeg = 3 * 0.35 * (1 - x) * x * x;
// 	let fourthSeg = 1 * x * x * x;
// 	console.log('firstSeg', firstSeg);
// 	console.log('secondSeg', secondSeg);
// 	console.log('thirdSeg', thirdSeg);
// 	console.log('fourthSeg', fourthSeg);
// 	return firstSeg + secondSeg + thirdSeg + fourthSeg - 0.31884195331;
// }

// function testBezY(x) {
// 	console.log('Testing: ', x);
// 	console.log('X: ' + x + ' is at ' + x / 2.447 * 100 + ' percent');
// 	let y = bezY(x);
// 	console.log('Y: ' + y + ' is at ' + y / 4.11725 * 100 + ' percent');
// }

// i dont think this works properly but not touching in case others use it
// jk i think it was just called too early before everythign loaded
export const getPageWidth = () => {
	return Math.max(
		document.body.scrollWidth,
		document.documentElement.scrollWidth,
		document.body.offsetWidth,
		document.documentElement.offsetWidth,
		document.documentElement.clientWidth
	);
};

export const getScrollPosition = () => {
	let scrollPosition =
		(window.pageXOffset || document.documentElement.scrollLeft) -
		(document.documentElement.clientLeft || 0);
	return scrollPosition;
};

export const getIdealProjectScrollPosition = projectIndex => {
	let projectClassName = 'project-view--' + ProjectsData.projectsRoutes[projectIndex].substring(1);
	let projectBoundingBox = document.getElementsByClassName(projectClassName)[0].getBoundingClientRect();
	let offsetX = (window.innerWidth - projectBoundingBox.width) / 2; // This centers it
	return projectBoundingBox.x - offsetX;
};

export const getOneRem = () => {
	return parseFloat(window.getComputedStyle(document.getElementsByTagName('html')[0]).fontSize);
};

/*** BELOW ARE ALL OLD, SOME MAY BE UNUSED ***/

export function getTheoreticalWidth(projectIndex) {
	let contentWidth = 33,
		projectsImages = [28.75, 25, 20, 22.279, 25];
	// BCA Electives is 21.25 (imageWidth) + 1.029 (overextension of title)

	return (projectsImages[projectIndex] + contentWidth) * getOneRem();
}

export function getTheoreticalImageWidth(projectIndex) {
	let projectsImages = [28.75, 25, 20, 21.25, 25];
	return projectsImages[projectIndex] * getOneRem();
}

export function getTheoreticalX(projectIndex) {
	let marginRight = 8;

	let scrollPosition = 0;
	for (let i = 0; i < projectIndex; i++) {
		scrollPosition += getTheoreticalWidth(i) + marginRight * getOneRem();
	}

	return scrollPosition;
}

export function getProjectScrollPosition(projectIndex) {
	return getTheoreticalX(projectIndex) - window.outerWidth / 5;
}

export function getTransformValue(target, index) {
	return parseFloat(
		window
			.getComputedStyle(target)
			.webkitTransform.split(', ')
			[index].replace(')', '')
	);
}

export function getTransformY(target) {
	if (target && target.style) {
		let replaced = target.style.transform
			.replace('translate(', '')
			.replace(') matrix(', '|')
			.replace(')', '');

		return parseFloat(
			replaced
				.split('|')[0]
				.split(', ')[1]
				.replace('%', '')
		);
	}

	return 0;
}
