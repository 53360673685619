import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

export default class StoryBlockImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className={
          'story__image ' +
          (this.props.small ? 'story__image--small ' : '') +
          (this.props.wide ? 'story__image--wide ' : '') +
          (this.props.smallEnd ? 'story__image--smallEnd' : '')
        }
      >
        <img className="story__image-content" src={this.props.src} alt={this.props.alt} />
      </div>
    );
  }
}
