import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, CSSPlugin } from 'gsap/all';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from '../components/SplitParagraph';
import AnimateText from '../components/AnimateText';

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stylesHome: {}
      // scrollPosition: null
    };

    this.homeTitle = null;
    this.homeDescription = null;
    this.homeDescriptionAccent = null;
    this.homeCTA = null;

    this.timelineLoad = null;
    this.hasSoughtEnd = false;
  }

  static contextTypes = Constants.contextTypesTransitionOnly;

  componentDidMount() {
    this.timelineLoad = new TimelineMax({ paused: true })
      .to({}, 1.02, {})
      .add(() => TweenMax.to(this.homeTitlePreload, 0.8, { ease: 'Mo', opacity: 0.5 }))
      .to({}, 0.2, {})
      .add(() => this.homeDescription.play())
      // .to({}, 0.5, {})
      .to({}, 0.6, {})
      .add(() => this.homeDescriptionAccent.play())
      // .to({}, 0.1, {})
      .to({}, 0.2, {})
      .add(() => this.homeCTA.play())
      .to({}, 0.1, {})
      .add(() => TweenMax.to(this.homeWords, 0.8, { ease: 'Mo', opacity: 1 }))
      .to({}, 0.1, {})
      .add(() => TweenMax.to(this.homeTitle, 0.8, { ease: 'Mo', opacity: 1 }));

    this.loadHome();
  }

  loadHome = () => {
    if (this.props.location.pathname == '/') {
      this.timelineLoad.play();
      this.hasSoughtEnd = true;
    }
  };

  seekToEnd = () => {
    if (!this.hasSoughtEnd) {
      this.timelineSeekToEnd = new TimelineMax()
        .add(() => this.timelineLoad.restart().progress(1))
        .add(() => this.homeDescription.seekToEnd())
        .add(() => this.homeDescriptionAccent.seekToEnd())
        .add(() => this.homeCTA.seekToEnd());
    }

    this.hasSoughtEnd = true;
  };

  updateProjectsOpacity = (value, time) => {
    TweenMax.to(this.home, time, { ease: 'Mo', opacity: value });
    TweenMax.to(this.homeWords, time, { ease: 'Mo', opacity: value });
  };

  scrollToProject = () => {
    //calc(100vw - 76.25rem + 8rem)
    let oneRem = Calculate.getOneRem();
    let marginRight = window.innerWidth - oneRem * 76.25 + oneRem * 8;
    let offsetLeft = window.innerWidth / 10 - (oneRem * 96) / 16;
    let scrollDestination = window.innerWidth + marginRight + offsetLeft;

    console.log('marginRight', marginRight);

    TweenMax.to(window, 0.8, {
      ease: 'Mo',
      scrollTo: {
        y: 0,
        x: scrollDestination
      }
    });
  };

  remove = () => {
    this.setState({ stylesHome: { display: 'none' } });
  };

  onEnterHome = node => {
    if (node) {
      this.setState({ stylesHome: {} });
    }
  };

  onEnteringHome = node => {};

  onEnteredHome = node => {
    if (node) {
      // console.log('ENTERED HOME ' + node);
    }
  };

  onExitHome = node => {
    if (node) {
      //       console.log('onEXIThome', node);
      //       let scrollPosition =
      //         (window.pageXOffset || document.documentElement.scrollLeft) -
      //         (document.documentElement.clientLeft || 0);
      //
      //       this.setState({ scrollPosition: scrollPosition });
    }
  };

  onExitedHome = node => {
    if (node) {
      this.remove();
    }
  };

  RouteHome = () => (
    <section className="home" ref={section => (this.home = section)} style={this.state.stylesHome}>
      <div className="home__content">
        <div className="home__content__container">
          <div className="home__title">
            <h1 className="home__title__content--preload" ref={h1 => (this.homeTitlePreload = h1)}>
              Hi, I&rsquo;m Jay Mo and this is my work.
            </h1>
            <h1 className="home__title__content" ref={h1 => (this.homeTitle = h1)}>
              Hi, I&rsquo;m <span className="home__title__content__mask">Jay Mo</span> and this is my work.
            </h1>
          </div>
          <div className="home__description">
            <SplitParagraph
              className="home__description__content"
              ref={el => (this.homeDescription = el)}
              identifier="hd-1"
            >
              To my family and many around me, I’m a lost 19 year old going through a rebellious phase. A
              postponement of college was not well-taken by them, and during the gap year I ended up burning
              out. But as an execution-focused product designer capable of front-end development, I love
              building well-detailed products for people and I understand the value of it. Now is a pivotal
              life moment, and I need guidance.
            </SplitParagraph>
            <p className="home__description__content--accent">
              <AnimateText ref={el => (this.homeDescriptionAccent = el)}>
                That's why I dream of and want to work for you so badly.
              </AnimateText>
            </p>
          </div>
          <div className="home__cta">
            <p className="home__cta__content" onClick={this.scrollToProject}>
              <AnimateText ref={el => (this.homeCTA = el)}>
                View work <span className="icon--arrow-right" />
              </AnimateText>
            </p>
          </div>
        </div>
      </div>
    </section>
  );

  RouteWords = () => (
    <section className="words" ref={section => (this.homeWords = section)} style={this.state.stylesHome}>
      <div className="words__column--1">
        <h4 className="words__column__content">born @ seoul, now @ nyc</h4>
      </div>
      <div className="words__grid">
        <div className="words__column--2">
          <h4 className="words__column__content--top">to josh williams</h4>
          <h4 className="words__column__content--bottom">jay@jaymo.io</h4>
        </div>
        <div className="words__column--3">
          <h4 className="words__column__content--top">
            <div>designed and developed</div>
            <div>by me</div>
          </h4>
          <h4 className="words__column__content--bottom">+1 201 925 1066</h4>
        </div>
        <div className="words__column--4" />
        <div className="words__column--5" />
        <div className="words__column--6">
          <h3 className="words__column__content--top">portfolio 2020</h3>
          <h4 className="words__column__content--bottom">
            <a href="https://twitter.com/jayhxmo" target="_blank">
              @jayhxmo
            </a>
          </h4>
        </div>
      </div>
      <div className="words__column--7" />
    </section>
  );

  RouteHomeNull = () => <div className="home-tracker" />;

  render() {
    const { RouteHome, RouteWords } = this;

    return (
      <TransitionGroup component={null}>
        <Route path="/" component={this.RouteHome} location={this.props.location} />
        <Route path="/" component={this.RouteWords} location={this.props.location} />
        <Transition
          appear
          key={'#home-' + (this.props.location.pathname == '/')}
          onEnter={node => {
            // setTimeout(() => {
            this.onEnterHome(node);
            // if (node) alert('onEnterHome');
            // }, this.context.transitionDelay)
          }}
          onEntering={node => {
            // setTimeout(() => {
            this.onEnteringHome(node);
            // }, this.context.transitionDelay)
          }}
          onEntered={node => {
            // setTimeout(() => {
            this.onEnteredHome(node);
            // if (node) alert('onEnteredHome');
            // }, this.context.transitionDelay)
          }}
          onExit={node => {
            // setTimeout(() => {
            this.onExitHome(node);
            // if (node) alert('onExitHome');
            // }, this.context.transitionDelay)
          }}
          onExited={node => {
            // setTimeout(() => {
            this.onExitedHome(node);
            // if (node) alert('onExitedHome');
            // }, this.context.transitionDelay)
          }}
          // timeout={1295}
          // timeout={1310} // 10 milliseconds just to be safe, should be "First" regardless
          // timeout={1290} // this is normal, but causes a "jump"
          timeout={1300}
        >
          <Route exact path="/" component={this.RouteHomeNull} location={this.props.location} />
        </Transition>
      </TransitionGroup>
    );
  }
}
