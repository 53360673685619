import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

// import * as ProjectsData from '../components/ProjectsData';

import SplitParagraph from '../SplitParagraph';
import AnimateText from '../AnimateText';
// import SplitParagraph from '../SplitParagraph';
// import AnimateText from '../AnimateText';

import StoryBlock from './StoryBlock';
import StoryBlockColumn from './StoryBlockColumn';
import StoryBlockImage from './StoryBlockImage';
import StoryBlockScrollshot from './StoryBlockScrollshot';
import StoryBlockVideo from './StoryBlockVideo';
import StoryBlockGroup from './StoryBlockGroup';
import StoryTimeline from './StoryTimeline';

import * as Calculate from '../Calculate';

import NextCaseStudy from './NextCaseStudy';

export default class BCAElectives extends React.Component {
  constructor(props) {
    super(props);
    this.state = { renderStoryTimeline: false };

    this.timeline = null;

    // this.title = React.createRef();
    this.videoTagging = null;
    this.videoTutorials = null;

    this.chaptersData = new Array(11);
    this.chaptersRef = new Array(11);

    this.milestonePoint = null;

    this.storyTimelineRef = null;
    this.nextCaseStudyRef = null;
  }

  componentDidMount() {
    this.chaptersData = [
      { title: 'The Beginning', time: 'December 2015', ref: this.chaptersRef[0] },
      { title: 'Creating The Golden Standard', time: 'Spring 2017', ref: this.chaptersRef[1] },
      { title: 'To Be Used Less', time: 'Spring 2017', ref: this.chaptersRef[2] },
      { title: 'For Mobile', time: 'Spring 2017', ref: this.chaptersRef[3] },
      { title: 'Summer', time: 'Summer 2017', ref: this.chaptersRef[4] }
    ];

    this.onScrollThrottled = _.throttle(this.onScroll, 50);
    window.addEventListener('scroll', this.onScrollThrottled);
    window.addEventListener('resize', this.onResize);

    this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
  }

  componentWillUnmount() {
    // if (this)
    window.removeEventListener('scroll', this.onScrollThrottled);
    window.removeEventListener('resize', this.onResize);
  }

  onScroll = () => {
    if (this.chaptersRef && this.chaptersRef[0]) {
      let theBeginningX = this.chaptersRef[0].container.getBoundingClientRect().x;
      // console.log('theBeginningX', theBeginningX);
      // console.log('themilestone', this.milestonePoint);
      // console.log('-------------');
      if (
        !this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x <= this.milestonePoint
      ) {
        this.setState({ renderStoryTimeline: true });
        this.forceUpdate();
      } else if (
        this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x >= this.milestonePoint
      ) {
        this.storyTimelineRef.unplay();
      }
    }

    if (
      this.videoExperience &&
      this.videoExperience.videoContainer &&
      this.videoExperience.videoContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      this.videoExperience.play();
    }
  };

  unrenderStoryTimeline = () => {
    this.setState({ renderStoryTimeline: false });
    this.forceUpdate();
  };

  onResize = () => {
    _.throttle(() => {
      this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
    }, 250);
  };

  render() {
    return (
      <>
        {this.state.renderStoryTimeline && (
          <StoryTimeline
            ref={el => (this.storyTimelineRef = el)}
            chaptersData={this.chaptersData}
            unrenderStoryTimeline={this.unrenderStoryTimeline}
            nextCaseStudyRef={this.nextCaseStudyRef}
          />
        )}
        <StoryBlock title="The Beginning" ref={el => (this.chaptersRef[0] = el)}>
          <StoryBlockColumn>
            <p>
              In December of 2015, Bryan Respass, a computer science teacher at BCA, gathered a team of seven
              of his students. Known as "Career Day," the school was to invite working professionals from
              various industries to talk to classes of students in multiple workshop sessions.
            </p>
            <p>
              The school administration briefed us about the need for an app for the upcoming school event.
              The previously existing apps were unmaintainable and not extendable. In two months, working two
              hours weekly during class, we would have to create a functional application from scratch.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              The seven of us split into two teams, four of us working on the app and three creating all other
              assets and tools needed. Building strictly to the given requirements and needs, we very
              successfully created and launched the app for the event.
            </p>
            <p>
              This app would serve as the model for many apps that followed months after, with summer
              internships and classes being structured for it. However, new people on the team took over
              without strongly established guidelines, and the apps quickly turned into inconsistent and
              "hacky" experiences.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              More problematic was that mobile experiences for these apps were an afterthought when many
              students accessed these apps from their smartphones. The new modern apps quickly became the same
              poor experiences of the 1990s version they initially aimed to fix.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="Creating The Golden Standard" ref={el => (this.chaptersRef[1] = el)}>
          <StoryBlockColumn>
            <p>
              By April 2017, the school and student body were experiencing a new issue. While the apps were
              becoming increasingly frustrating to use, the enabling of individual class registration created
              strong competition among students.
            </p>
            <p>
              Some classes would be out of seats within minutes, while others would never fill up. It became
              similar to when people buy tickets for a high demand concert. Everyone gets ready minutes before
              the site is live, and clicks their way through furiously.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              As a student myself, I understood that the core problem was that the school was offering the
              wrong classes. There was a need for more of higher demanded courses, while it wouldn't make
              sense for the teacher to provide a course if only a few students would ever enroll in it.
            </p>
            <p>
              With my talent friend Sam Olagun, we set out to create an application for students to be able to
              better communicate their course interests and needs to the faculty. This would also be designed
              and coded with the highest standard, to serve as the golden standard for all future apps.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="To Be Used Less" ref={el => (this.chaptersRef[2] = el)}>
          <StoryBlockColumn>
            <p>
              Often times, "how long do people spend on your app?" is a metric used to measure product
              success. For us, it was the opposite. Based on our own experiences and observations of our
              peers, we understood that no one wanted to spend time with this during their already busy day.
            </p>
            <p>
              We would measure our success in two ways: inverse of time spent on the app and the count of
              fulfilled classes. We imagined a fluid experience where students could log in, be guided into a
              singular flow, set their preferences, and close out the application — all in just seconds.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              The quality of execution and craft was very important. The choice of typography prioritizes
              readability, while also giving an academic vibe. The usage of yellows and blacks was based on
              the school's brand and mascot.
            </p>
            <p>
              Given the popularity of tablet PCs among students, we made sure this was a friendly interface
              for touch pointers and chose to rely on a Card system.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup>
            <StoryBlockVideo
              ref={el => (this.videoExperience = el)}
              identifier="electives-experience"
              src="//portfolio-cdn.jayhxmo.now.sh/bca-electives/experience.mp4"
              wide={true}
            />
          </StoryBlockGroup>
          <StoryBlockColumn>
            <p>
              One of the most confusing and complicated tasks for students was manually matching their
              schedules to the web apps. The school was using a system with 27 "mods" per day, with classes
              being either 2 ~ 3 mod blocks. Each mod was 15 minutes long with 3 minutes of break in between
              each, so they could not be easily referred by the actual hours.
            </p>
            <p>Confusing, right?</p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              To add to the frustration, the school was using third-party software for student schedules,
              which did not communicate data with the web apps. Students had to input their own schedule
              manually, and it was terrible.
            </p>
            <p>
              With help from other students who built an unofficial API to the third-party software, we
              designed a system where we simplified and auto-filled the students' schedules. It was a major
              improvement.
            </p>
          </StoryBlockColumn>
          <StoryBlockImage
            src="//portfolio-cdn.jayhxmo.now.sh/bca-electives/schedule.png"
            alt="Schedule stage of the experience"
            wide={true}
          />
          <StoryBlockColumn>
            <p>
              We noticed that a contributor to the problem of certain classes being over-scheduled was due to
              the course and teacher reputation. While this is natural, we wanted to prevent students from
              blindly selecting "easy As" based on reputation. By understanding their interests, we wanted to
              guide them in discovering niche classes they weren't aware of but would enjoy.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup end={true}>
            <StoryBlockImage
              src="//portfolio-cdn.jayhxmo.now.sh/bca-electives/topics.png"
              alt="Topics stage of the experience"
              wide={true}
            />
          </StoryBlockGroup>
        </StoryBlock>
        <StoryBlock title="For Mobile" ref={el => (this.chaptersRef[3] = el)}>
          <StoryBlockColumn>
            <p>
              With the desktop experience designed with touch devices in mind, it was simple to translate the
              same experience to mobile. We understood that others would end up extending the app and planned
              for a responsive site from the very start to ease the future dev efforts.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup end={true}>
            <StoryBlockImage src="//portfolio-cdn.jayhxmo.now.sh/bca-electives/mobile.png" alt="Mobile screens of the experience" />
          </StoryBlockGroup>
        </StoryBlock>
        <StoryBlock title="Summer" ref={el => (this.chaptersRef[4] = el)}>
          <StoryBlockColumn>
            <p>
              This project was worked on in April 2017 during the middle of my junior year in high school,
              which is regarded as the busiest and most important to colleges. Only a month later were AP
              tests, which were followed by finals and tests as part of the end of the trimester.
            </p>
            <p>
              While we were confident in what we had planned and designed, Sam and I held off the development
              until we met our academic needs. However, when it was all over, summer came, and we were off to
              our own respective internships and work.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              Though this project was never built in the end, it was our way as two students to try to improve
              the already tough school life for our peers.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <NextCaseStudy
          ref={el => (this.nextCaseStudyRef = el)}
          index={4}
          reunloadProjectToProject={this.props.reunloadProjectToProject}
          location={this.props.location}
        />
        {/* <StoryBlock> */}
        {/*   <StoryBlockColumn> */}
        {/*   </StoryBlockColumn> */}
        {/* </StoryBlock> */}
      </>
    );
  }
}
