import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import ScrollLock from 'react-scrolllock';
import { TimelineMax, TweenMax, CSSPlugin } from 'gsap/all';
import _ from 'underscore';

// import * as ProjectsData from '../components/ProjectsData';

import SplitParagraph from '../SplitParagraph';
import AnimateText from '../AnimateText';
// import SplitParagraph from '../SplitParagraph';
// import AnimateText from '../AnimateText';

import StoryBlock from './StoryBlock';
import StoryBlockColumn from './StoryBlockColumn';
import StoryBlockImage from './StoryBlockImage';
import StoryBlockScrollshot from './StoryBlockScrollshot';
import StoryBlockVideo from './StoryBlockVideo';
import StoryBlockGroup from './StoryBlockGroup';
import StoryTimeline from './StoryTimeline';

import * as Calculate from '../Calculate';

import NextCaseStudy from './NextCaseStudy';

export default class Blazescout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { renderStoryTimeline: false };

    this.timeline = null;

    // this.title = React.createRef();
    this.videoHome = null;
    this.videoProduct = null;

    this.chaptersData = new Array(11);
    this.chaptersRef = new Array(11);

    this.milestonePoint = null;

    this.storyTimelineRef = null;
    this.nextCaseStudyRef = null;
  }

  componentDidMount() {
    this.chaptersData = [
      { title: 'The Beginning', time: 'December 2015', ref: this.chaptersRef[0] },
      { title: 'Research and Redirection', time: 'Spring 2017', ref: this.chaptersRef[1] },
      { title: 'Defining Experiences', time: 'Spring 2017', ref: this.chaptersRef[2] },
      { title: 'Iterations', time: 'Spring 2017', ref: this.chaptersRef[3] },
      { title: 'End for Me, Start for Them', time: 'Summer 2017', ref: this.chaptersRef[4] }
    ];

    this.onScrollThrottled = _.throttle(this.onScroll, 50);
    window.addEventListener('scroll', this.onScrollThrottled);
    window.addEventListener('resize', this.onResize);

    this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
  }

  componentWillUnmount() {
    // if (this)
    window.removeEventListener('scroll', this.onScrollThrottled);
    window.removeEventListener('resize', this.onResize);
  }

  onScroll = () => {
    if (this.chaptersRef && this.chaptersRef[0]) {
      let theBeginningX = this.chaptersRef[0].container.getBoundingClientRect().x;
      // console.log('theBeginningX', theBeginningX);
      // console.log('themilestone', this.milestonePoint);
      // console.log('-------------');
      if (
        !this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x <= this.milestonePoint
      ) {
        this.setState({ renderStoryTimeline: true });
        this.forceUpdate();
      } else if (
        this.state.renderStoryTimeline &&
        this.chaptersRef[0].container.getBoundingClientRect().x >= this.milestonePoint
      ) {
        this.storyTimelineRef.unplay();
      }
    }

    if (
      this.videoHome &&
      this.videoHome.videoContainer &&
      this.videoHome.videoContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      this.videoHome.play();
    }

    if (
      this.videoProduct &&
      this.videoProduct.videoContainer &&
      this.videoProduct.videoContainer.getBoundingClientRect().x < window.innerWidth * 0.7
    ) {
      this.videoProduct.play();
    }
  };

  unrenderStoryTimeline = () => {
    this.setState({ renderStoryTimeline: false });
    this.forceUpdate();
  };

  onResize = () => {
    _.throttle(() => {
      this.milestonePoint = (Calculate.getOneRem() * 96) / 16 + window.innerWidth / 10;
    }, 250);
  };

  render() {
    return (
      <>
        {this.state.renderStoryTimeline && (
          <StoryTimeline
            ref={el => (this.storyTimelineRef = el)}
            chaptersData={this.chaptersData}
            unrenderStoryTimeline={this.unrenderStoryTimeline}
            nextCaseStudyRef={this.nextCaseStudyRef}
          />
        )}
        <StoryBlock title="The Beginning" ref={el => (this.chaptersRef[0] = el)}>
          <StoryBlockColumn>
            <p>
              My relationship with Joe Zaghloul and Nico Roberts began in the summer of 2016 when I interned
              for OnboardIQ. As a small startup of a dozen members, we all became very close to one another.
              During the summer, I specifically worked under Joe, the Head of Sales, to revamp and create all
              design assets needed for the Sales and Marketing teams.
            </p>
            <p>
              A few months later, Joe and Nico both left OnboardIQ to co-found Nebula, a startup started by
              Joe's best friend.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              The mission was to build a community of cannabis growers, manufacturers, and dispensaries with
              technology solutions to enable their growth further.
            </p>
            <p>
              Joe knew I was trying to break out of marketing design work into product design, which was much
              more dear to my heart. Nebula was an early-stage startup without funding. I was a young, hungry
              designer looking to gain experience and learn. It was a great match.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              At the time, Nebula was mistakenly in conversations with investors to try to raise starting
              funds. Without any real grounds for a product and only a mission as guidance, it was assumed
              that Nebula's service would be to help local cannabis companies with branding, logistics, sales,
              and marketing. An online dashboard, as the software product, would make the entire process more
              efficient and effective. For the first several weeks, I would end up doing conceptual work, as
              shown in the right.
            </p>
          </StoryBlockColumn>
          <StoryBlockImage
            src="//portfolio-cdn.jayhxmo.now.sh/blazescout/concept.png"
            alt="Early concept work"
            small={true}
            smallEnd={true}
          />
        </StoryBlock>
        <StoryBlock title="Research and Redirection" ref={el => (this.chaptersRef[1] = el)}>
          <StoryBlockColumn>
            <p>
              After many conversations with investors fell through, we realized that we needed a real product
              before these conversations could fully happen. We started talking to cannabis brands and
              consumers to understand where they struggled and how we could help.
            </p>
            <p>
              For consumers, it came down to one thing: discovering cannabis products was too difficult. While
              there were select brands that did have websites full of products and information, there was no
              centralized platform for finding, comparing, and purchasing cannabis products.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              For brands, there were several problems. First was brand and marketing. Logos were hardly
              identifiable. Many of these brands used unappealing photos for their edible products, along with
              unfriendly names no one could remember. Second was interaction and exposure to consumers. With
              no online presence, all consumer-to-brand interaction was offline. They had weak relationships
              with their consumers and were missing out on a lot of potential sales, revenue, and growth.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              We understood that some of these problems could not be solved with just software. We would have
              to hire designers and photographers and individually assist each brand that needed help.
            </p>
            <p>
              However, we hypothesized that we could create a marketplace for cannabis products. Brands and
              their products would gain exposure through the platform, and consumers would have an extensive
              option for discovering cannabis products.
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <StoryBlock title="Defining Experiences" ref={el => (this.chaptersRef[2] = el)}>
          <StoryBlockColumn>
            <p>
              While this was the first version of the platform before many iterations, one thing was clear: it
              had to be for the consumers. Without demand from consumers, the platform would have no value for
              the brands.
            </p>
            <p>It had to be product-centric.</p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              At launch, we knew there would only be a limited selection of products from the few partnered
              brands. We quickly drafted site structures and layouts that would feature the partnered products
              while being frictionless.
            </p>
          </StoryBlockColumn>
          <StoryBlockGroup end={true}>
            <StoryBlockImage src="//portfolio-cdn.jayhxmo.now.sh/blazescout/wireframes.png" alt="Early wireframes" small={true} />
          </StoryBlockGroup>
        </StoryBlock>
        <StoryBlock title="Iterations" ref={el => (this.chaptersRef[3] = el)}>
          <StoryBlockColumn>
            <p>
              We wanted the site to be inviting to curious visitors, while also exciting current cannabis
              consumers. With a modern typeface and bright green inspired by cannabis flowers, the platform
              was designed to excite and represent the technology solutions Nebula was bringing to the
              community.
            </p>
          </StoryBlockColumn>
          <StoryBlockVideo
            ref={el => (this.videoHome = el)}
            identifier="blazescout-home"
            src="//portfolio-cdn.jayhxmo.now.sh/blazescout/blazescout-home.mp4"
          />
          <StoryBlockColumn>
            <p>
              With a home page that was inviting, the content for all the cannabis products was organized in a
              dedicated Products page. We understood the importance of consumer interaction with products and
              brands and experimented with the idea of upvotes and comments.
            </p>
            <p>
              Organized chronologically by default, the Products page would have something exciting and new
              for consumers to discover on every visit. It would give allow consumers to discover new and
              niche products, while the flagship products would get the exposure they deserved.
            </p>
          </StoryBlockColumn>
          <StoryBlockVideo
            ref={el => (this.videoProduct = el)}
            identifier="blazescout-product"
            src="//portfolio-cdn.jayhxmo.now.sh/blazescout/blazescout-product.mp4"
            end={true}
          />
        </StoryBlock>
        <StoryBlock title="End for Me, Start for Them" ref={el => (this.chaptersRef[4] = el)}>
          <StoryBlockColumn>
            <p>
              I worked on this project remotely in the middle of my junior year of high school, which is
              regarded as the busiest. While the first version did take multiple iterations to arrive at, I
              had to focus on school and could not continue with the team for future versions. The team also
              needed more dedicated members who could work more than just 15 ~ 20 hours a week.
            </p>
          </StoryBlockColumn>
          <StoryBlockColumn>
            <p>
              I was super thankful that I could be the sole designer of an early product as a kid—it was a
              great learning experience. Eventually, Nebula ended up rebranding to Rootery, and Blazescout
              became the main product of the company. They are still alive, and they're killing it!
            </p>
          </StoryBlockColumn>
        </StoryBlock>
        <NextCaseStudy
          ref={el => (this.nextCaseStudyRef = el)}
          index={0}
          reunloadProjectToProject={this.props.reunloadProjectToProject}
          location={this.props.location}
        />
        {/* <StoryBlock> */}
        {/*   <StoryBlockColumn> */}
        {/*   </StoryBlockColumn> */}
        {/* </StoryBlock> */}
      </>
    );
  }
}
