import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TimelineMax, CSSPlugin } from 'gsap/all';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

import SplitParagraph from '../components/SplitParagraph';
import AnimateText from '../components/AnimateText';

import ProjectView from '../components/ProjectView';

export default class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isCaseStudy: false };
    this.projectsRef = new Array(5);
    this.projectsContainer = null;

    this.originalPath = '';

    this.scrollPosition = 0;
    this.isTransitioning = false;

    window.addEventListener('scroll', this.onScroll);
  }

  static contextTypes = Constants.contextTypesRouterOnly;

  componentWillMount() {
    if (this.props.location.pathname != '/') {
      let originalPath = this.props.location.pathname;

      this.context.router.history.replace('/');
      this.context.router.history.push(originalPath);

      this.props.pushHistory('/');
      this.props.pushHistory(originalPath);
    }
  }

  componentDidMount() {
    // window.onpopstate = function() { };
  }

  onScroll = () => {
    if (this.isTransitioning && false) {
      console.log('LOCKING SCROLL???? ', this.scrollPosition);
      window.scroll(this.scrollPosition, 0);
      this.isTransitioning = false;
    }
  };

  setTransitionScroll = (isTransitioning, scrollPosition) => {
    this.isTransitioning = isTransitioning;
    if (scrollPosition) this.scrollPosition = scrollPosition;
  };

  updateProjectsOpacity = (projectIndex, value, time) => {
    this.projectsRef.forEach((project, index) => {
      if (index != projectIndex) {
        TweenMax.to(this.projectsRef[index].projectView, time, { ease: 'Mo', opacity: value });
      }
    });
  };

  unloadProject = projectIndex => {
    this.projectsRef[projectIndex].unload();
  };

  reloadProject = projectIndex => {
    this.projectsRef[projectIndex].reload();
  };

  instantUnloadProject = projectIndex => {
    this.projectsRef[projectIndex].instantUnload();

    // return;
    // setTimeout(() => {
    this.projectsRef.forEach((project, index) => {
      this.projectsRef[index].onCaseStudyEnter(true);
      if (index != projectIndex) {
        this.projectsRef[index].remove();
      }
    });
    // }, 1000);

    // This is probably not needed
    // And unwanted as well (since it sets absolute positioning on images)
    // this.projectsRef[projectIndex].setCaseStudyEnter();
  };

  instantReloadProject = (projectIndex, instantReloadText) => {
    this.projectsRef[projectIndex].instantReload(instantReloadText);
    this.projectsRef[projectIndex].onHomeEnter(true, true);

    this.projectsRef.forEach((project, index) => {
      if (index != projectIndex) {
        // onHomeEntered for non-active projects is redundant, .show() covers it
        // and does more for this case by enabling images again

        // this.projectsRef[index].onHomeEntered(true);
        this.projectsRef[index].show();
      }
    });

    // alert('LOCATION AT: ' + this.props.location.pathname);

    // onHomeEntered is necessary when going from project to project as we need to emulate it
    // But it breaks instantReloadText() if the path is HOME
    // Becuase onHomeEntered (used to get) triggered below, and it naturally gets triggered as well
    this.projectsRef[projectIndex].onHomeEntered(true, true);
  };

  hide = projectIndex => {
    this.projectsRef.forEach((project, index) => {
      this.projectsRef[index].hide(projectIndex);
    });
  };

  // @ old
  // @ deprecated
  setCaseStudyMode = value => {
    alert('using deprecated code');
    this.setState({ atCaseStudy: value });
    // alert('setCaseStudyMode');
  };

  setCaseStudyStatus = value => {
    this.setState({ isCaseStudy: value });
  };

  clearProjectsGSAPStates = projectIndex => {
    this.projectsRef.forEach((project, index) => {
      this.projectsRef[index].clearProjectsGSAPStates();
    });
  };

  getProjectBoundingBox = projectIndex => {
    return this.projectsRef[projectIndex].getProjectBoundingBox();
  };

  getContainerBoundingBox = () => {
    return this.projectsContainer ? this.projectsContainer.getBoundingClientRect() : 0;
  };

  resetText = projectIndex => {
    alert('reset text');
    this.projectsRef[projectIndex].resetTextOnResize();
  };

  render() {
    const { RouteHome, RouteWords } = this;
    // let isProjectPath = ProjectsData.projectsRoutes.indexOf(this.props.location.pathname) != -1;

    return (
      <section
        className={'projects' + (this.state.isCaseStudy ? ' projects--case' : '')}
        ref={section => (this.projectsContainer = section)}
      >
        <div className="projects__content">
          <div className="projects__content__container">
            {/* <h1>{this.props.projectIndex}</h1> */}
            {ProjectsData.projectsData.map((project, index) => (
              <ProjectView
                key={'projectView-' + index}
                index={index}
                ref={el => (this.projectsRef[index] = el)}
                playProject={this.props.playProject}
                instantUnloadProject={this.props.instantUnloadProject}
                updateProjectIndex={this.props.updateProjectIndex}
                setCaseStudyStatus={this.setCaseStudyStatus}
                setTransitionScroll={this.setTransitionScroll}
                location={this.props.location}
                pushHistory={this.props.pushHistory}
                getHistory={this.props.getHistory}
                getContainerBoundingBox={this.getContainerBoundingBox}
                setInstantState={this.props.setInstantState}
                getInstantState={this.props.getInstantState}
              />
            ))}
          </div>
        </div>
      </section>
    );
  }
}
