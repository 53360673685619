import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';

import * as ProjectsData from '../ProjectsData';

export default class NextCaseStudy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.container = null;

    this.cssTitle = ProjectsData.projectsData[this.props.index].title.toLowerCase().replace(/ /g, '-');
  }

  componentDidMount() {
    // console.log(this.props.location);
  }

  // THIS IS COPIED FROM NAVIGATION.JSX
  // NNEVER MIND WE SIMPLIFIED IT
  navigateTo = destination => {
    const originIndex = ProjectsData.projectsRoutes.indexOf(this.props.location.pathname),
      destinationIndex = ProjectsData.projectsRoutes.indexOf(destination);

    this.props.reunloadProjectToProject(originIndex, destinationIndex, 0, 0, 0);

    //     if (originIndex != -1 && originIndex != destinationIndex) {
    //       window.scroll(0, 0);
    //
    //       if (destinationIndex != -1) {
    //         this.props.reloadProject(originIndex);
    //         this.forceUpdate();
    //         setTimeout(() => {
    //           this.props.unloadProject(destinationIndex);
    //         }, 100);
    //       } else if (destination == '/') {
    //         this.props.setInstantState('instantReloadMode', true);
    //       }
    //     } else if (this.props.location.pathname == '/' && destinationIndex != -1) {
    //       this.props.setInstantState('instantUnloadMode', true);
    //     }
  };

  RouteImage = () => (
    <div className="project-view__image">
      <div className="project-view__image__image">
        <Link to={'/' + this.cssTitle} onClick={() => this.navigateTo('/' + this.cssTitle)}>
          <div ref={el => (this.imageContent = el)}>
            <img
              className="project-view__image__image__content"
              ref={img => (this.image = img)}
              src={'//portfolio-cdn.jayhxmo.now.sh/projects/' + this.cssTitle + '.png'}
            />
          </div>
        </Link>
      </div>
    </div>
  );

  RouteDetails = () => (
    <div className="project-view__content">
      <div className="project-view__content__container">
        <div className="project-view__header">
          <div className="project-view__tags">
            {ProjectsData.projectsData[this.props.index].tags.map((tag, index) => (
              <h4 className="project-view__tags__tag">{tag}</h4>
            ))}
          </div>
          <div className="project-view__title">
            <Link to={'/' + this.cssTitle} onClick={() => this.navigateTo('/' + this.cssTitle)}>
              <h1 className="project-view__title__content">
                {ProjectsData.projectsData[this.props.index].title}
              </h1>
            </Link>
          </div>
        </div>
        <p className="project-view__description">{ProjectsData.projectsData[this.props.index].description}</p>
        <p className="project-view__cta">
          <Link to={'/' + this.cssTitle} onClick={() => this.navigateTo('/' + this.cssTitle)}>
            Next Case Study <span className="icon--arrow-right" />
          </Link>
        </p>
      </div>
    </div>
  );

  RouteImageSpacer = () => <div className="project-view__image-spacer" />;

  RouteImageNull = () => <div className="project-view__image-tracker" style={{ display: 'none' }} />;

  RouteContentNull = () => <div className="project-view__content-tracker" style={{ display: 'none' }} />;

  render() {
    return (
      <div
        className={'projects project--next-case ' + 'project--next-case--' + this.cssTitle}
        ref={div => (this.container = div)}
      >
        {/* <div className="projects__content"> */}
        {/*   <div className="projects__content__container"> */}
        <h1 className="project--next-case__title">Next</h1>
        <div className={'project-view project-view--' + this.cssTitle}>
          <Route
            exact
            path={['/', '/' + this.cssTitle]}
            component={this.RouteImageNull}
            location={this.props.location}
          />
          <Route path="/" component={this.RouteImageSpacer} location={this.props.location} />
          <Route path="/" component={this.RouteImage} location={this.props.location} />
          <Route path="/" component={this.RouteDetails} location={this.props.location} />

          <Route exact path="/" component={this.RouteContentNull} location={this.props.location} />
          {/*   </div> */}
          {/* </div> */}
        </div>
      </div>
    );
  }
}
