import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Switch, BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { TransitionGroup, Transition } from 'react-transition-group';
import CustomEase from '../plugins/CustomEase';
import _ from 'underscore';

import Intro from './intro';
import Base from './base';
import Home from './home';
import Projects from './projects';
import ComingSoon from './comingSoon';
import CaseStudy from './caseStudy';
import Navigation from './navigation';
import TransitionCover from './transitionCover';

import Mobile from './mobile';

import * as ProjectsData from '../components/ProjectsData';
import * as Calculate from '../components/Calculate';
import * as Constants from '../components/Constants';

require('../../styles/styles.scss');

export default class Index extends React.Component {
	constructor() {
		super();
		this.state = {
			// renderStates: {
			// 	intro: false,
			// 	home: true,
			// 	projects: true,
			// 	caseStudy: true, // should be true
			// 	base: true,
			// 	navigation: false
			// },
			renderStates: {
				intro: true,
				home: false,
				projects: false,
				caseStudy: false, // should be true
				base: false,
				navigation: false
			},
			history: [],
			hasHistoryInitialized: false,
			hasAnimated: false,
			isAnimating: false,
			projectIndex: -1,
			transitionDelay: 0,
			onMobile: window.innerWidth <= 760
		};

		// Used for very time sensitive variables, such as for animation
		// Does not use "state" cycle concept
		// Uses setters and getters
		this.instantState = {
			caseStudyScrollPosition: 0,
			instantUnloadMode: false,
			instantReloadMode: false,
			recentAction: [{ projectIndex: -1, action: '' }]
		};

		this.location = null;

		this.base = null;
		this.home = null;
		this.projects = null;
		this.caseStudy = null;
		this.comingSoon = null;
		this.transitionCover = null;

		this.windowResizeTimer = null;

		// @ DEPRECATED
		this.isPopping = false;
		this.canForwardPop = true;

		// this.idealProjectScrollPosition = [0, 0, 0, 0, 0];

		CustomEase.create('Mo', '0.64, 0.04, 0.35, 1');
	}

	static childContextTypes = {
		updateTransitionDelay: PropTypes.func,
		transitionDelay: PropTypes.number,
		isPopping: PropTypes.bool,
		canForwardPop: PropTypes.bool
	};

	getChildContext() {
		return {
			updateTransitionDelay: this.updateTransitionDelay,
			transitionDelay: this.state.transitionDelay,
			isPopping: this.isPopping,
			canForwardPop: this.canForwardPop
		};
	}

	componentDidMount() {
		// for (let i = 0; i < ProjectsData.projectsRoutes.length; i++) {
		// 	this.idealProjectScrollPosition[i] = Calculate.getIdealProjectScrollPosition(i);
		// }
		if ('scrollRestoration' in window.history) {
			window.history.scrollRestoration = 'manual';
			history.scrollRestoration = 'manual';
		}

		window.onpopstate = () => {
			this.onPopstateWithSpamHandler(0);
		};
		window.addEventListener('resize', this.onWindowResizeHandler);
		window.addEventListener('wheel', this.horizontalScrollHandler);

		console.log(
			`Hello~~! This was my first project using web animation technologies like GSAP along with React Router. I didn't do a great job planning out (since I had no idea what to expect), and it hurt a lot. That's why instead of trying to go through weeks of headaches trying to add new routes + animations, I snuck the About page here!`
		);
		console.log(`There's nothing too much but for starters:`);
		console.log(`1. I was born in Korea, moved to USA when I was 8`);
		console.log(
			`2. Maaaybe that was the inspiration, but I play and coach competitive StarCraft on my free time. Currently part of an amateur team called Daily Life eSports and designing for some tournaments!`
		);
		console.log(
			`3. My approach in life is to try not to be so calculative about everything. This applies to design, relationships... any decisions really! Of course they need to make logical sense, but I like to think that there's often something special and beautiful in being open and embracing inspiration.`
		);
		console.log(`That's all I can think of so far, but I'd love to learn more about you as well :)`);
	}

	componentWillUnmount() {
		// Index component should never be unmounted anyways but just in case
		window.removeEventListener('resize', this.onWindowResizeHandler);
		window.removeEventListener('wheel', this.horizontalScrollHandler);
	}

	horizontalScrollHandler = e => {
		if (!this.state.renderStates.navigation) {
			// console.log(e.deltaY);
			if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
				document.documentElement.scrollLeft += e.deltaY;
			}
		}
		// TweenMax.to(window, 0, {
		//       ease: 'Mo',
		//       scrollTo: {
		//         y: 0,
		//         x:
		//           this.props.chaptersData[index].ref.container.getBoundingClientRect().x -
		//           (Calculate.getOneRem() * 96) / 16 - // side bar padding
		//           window.innerWidth / 10 + // half of grid block
		//           Calculate.getScrollPosition()
		//       }
		// });
	};

	onPopstate = () => {
		const originIndex = ProjectsData.projectsRoutes.indexOf([...this.getHistory()].pop()),
			destinationIndex = ProjectsData.projectsRoutes.indexOf(this.location.pathname);

		if (originIndex != -1 && destinationIndex != -1 && originIndex != destinationIndex) {
			window.scroll(0, 0);
			this.reunloadProjectToProject(originIndex, destinationIndex, 0, 0, 0);
		} else {
			// Project to Home
			let scrollPosition = Calculate.getScrollPosition();
			if (this.caseStudy) {
				let backButtonBox = this.caseStudy.getBackButtonBoundingBox();

				if (backButtonBox == null || backButtonBox.x + backButtonBox.width < 0) {
					this.setInstantState('instantUnloadMode', true);
					this.setInstantState('instantReloadMode', true);
				} else {
					if (this.caseStudy) {
						// Trigger animation like it would when back button is clicked
						this.caseStudy.onBackClick();
						this.caseStudy.onCaseStudyExit(true, false);
					}
				}
			}
			// Home to Project
			// Might need to be more specific after adding About / Archives
			else {
				let projectBoundingBox = this.projects.getProjectBoundingBox(destinationIndex);
				// console.log('home to project has been called oh wow');
				// console.log(projectBoundingBox);
				// console.log(projectBoundingBox == null);
				// console.log(projectBoundingBox.x + projectBoundingBox.width < 0);
				// console.log(projectBoundingBox.x >= scrollPosition + window.innerWidth);
				// console.log('-------');
				if (
					projectBoundingBox == null ||
					projectBoundingBox.x + projectBoundingBox.width < 0 ||
					projectBoundingBox.x >= scrollPosition + window.innerWidth
				) {
					this.setInstantState('instantUnloadMode', true);
					this.setInstantState('instantReloadMode', true);
				} else {
					// Normal unload animation
				}
			}
		}
	};

	onPopstateWithSpamHandler = popstateSpamDelay => {
		if (this.state.isAnimating) {
			setTimeout(() => {
				this.onPopstateWithSpamHandler(100);
			}, popstateSpamDelay);
		} else {
			this.onPopstate();
		}
	};

	onWindowResizeHandler = () => {
		if (window.innerWidth <= 760) {
			this.setState({ onMobile: true });
		} else if (this.state.onMobile) {
			this.setState({ onMobile: false });
		}

		clearTimeout(this.windowResizeTimer);
		this.windowResizeTimer = setTimeout(() => {
			// Unnecessary check for desktop, but it breaks mobile resizing sometimes if without
			if (this.location) {
				let currentProjectIndex = ProjectsData.projectsRoutes.indexOf(this.location.pathname);
				if (currentProjectIndex != -1) {
					// Need to wait for React to update everything
					setTimeout(() => {
						this.reunloadProjectToProject(currentProjectIndex, currentProjectIndex, 0, 200, 0.2);
						// this.projects.resetText(currentProjectIndex);
					}, 250);
				}
			}
		}, 500);
	};

	updateRenderStates = (target, value) => {
		let newRenderStates = this.state.renderStates;
		newRenderStates[target] = value;
		this.setState({ renderStates: newRenderStates });
	};

	// Consider re-writing this to 1 generic instantState setter / getter
	setInstantState = (target, value) => {
		this.instantState[target] = value;
	};

	getInstantState = target => {
		return this.instantState[target];
	};

	setCaseStudyScrollPosition = scrollPosition => {
		this.instantState.scrollPosition = scrollPosition;
	};

	pushHistory = path => {
		// We use this because "getHistory().length > 0" does not work in all contexts
		if (!this.state.hasHistoryInitialized) {
			this.setState({ hasHistoryInitialized: true });
			// console.log(111111, 'HISTORY HAS INITIALIZED', path);
		}

		this.setState(prevState => {
			if (prevState.history.length == 0 || prevState.history[prevState.history.length - 1] != path) {
				return {
					history: [...prevState.history, path]
				};
			}
		});
		// () => console.log('HISTORY', this.history)
	};

	getHistory = () => {
		return this.state.history;
	};

	hasHistoryInitialized = () => {
		return this.state.hasHistoryInitialized;
	};

	updateMenuOpacity = (value, time) => {
		// This isn't the cleanest function
		// but needs an intermediary function
		// between navigation.jsx and base.jsx
		// when hiding navigation

		// I tried using this.props.updateMenuOpacity in Base.jsx
		// But for some reason refs are null
		this.base.updateMenuOpacity(value, time);
	};

	updateProjectsOpacity = (projectIndex, value, time) => {
		// console.log('UPO', projectIndex, value, time);
		if (this.home) this.home.updateProjectsOpacity(value, time);
		if (this.projects) this.projects.updateProjectsOpacity(projectIndex, value, time);
		if (this.comingSoon) this.comingSoon.updateProjectsOpacity(value, time);
	};

	playProject = projectIndex => {
		if (!this.state.isAnimating) {
			// alert('playProject');
			if (!this.state.hasAnimated) this.unloadProject(projectIndex);
			else this.reloadProject(projectIndex);
		}
	};

	unloadProject = projectIndex => {
		// if (false) {
		if (this.instantState.instantUnloadMode) {
			// if (this.context.isPopping) {
			// alert('unload on Pop');
			// console.log('unloadProject, instantUnloadMode');

			// @ NOTE
			// This delays by 400ms which is the exact timing
			// for the transitionCover to hide everything
			// This function gets called for only popstate anyways
			// this.setState({ isAnimating: true });
			this.animateTransitionCover(true, 0);
			setTimeout(() => {
				this.setInstantState('instantUnloadMode', false);
				this.setInstantState('instantReloadMode', false);
				this.instantUnloadProject(projectIndex);
				// this.setState({ isAnimating: false });
				this.instantState.recentAction.push({ projectIndex: projectIndex, action: 'instantUnload' });
			}, 800);
			// this.context.isPopping = false;
		} else {
			// alert('normal unload');
			// console.log('unload plays out?');
			this.setState({ isAnimating: true });

			this.projectsTimeline = new TimelineMax({
				onComplete: () => this.setState({ hasAnimated: true, isAnimating: false })
			})
				.add(() => this.updateProjectsOpacity(projectIndex, 0, 0.8))
				.add(() => this.projects.unloadProject(projectIndex));

			this.instantState.recentAction.push({ projectIndex: projectIndex, action: 'unload' });
		}
	};

	reloadProject = projectIndex => {
		// if (false) {
		// alert(this.instantState.instantReloadMode);
		// alert('reload project hit ' + this.instantState.instantReloadMode);
		if (this.instantState.instantReloadMode) {
			// if (this.context.isPopping) {
			// alert('reload on Pop');

			// @ NOTE
			// This delays by 400ms which is the exact timing
			// for the transitionCover to hide everything
			// This function gets called for only popstate anyways
			// this.caseStudy.onCaseStudyExit(true, true);
			// this.setState({ isAnimating: true });
			this.animateTransitionCover(false, 0);
			// alert('popstate reload project');
			setTimeout(() => {
				window.scroll(0, 0);
				this.setInstantState('caseStudyScrollPosition', 0);
				// console.log('instant Reload scrolls');
				// console.log(Calculate.getScrollPosition());
			}, 600);

			setTimeout(() => {
				this.setInstantState('instantUnloadMode', false);
				this.setInstantState('instantReloadMode', false);
				this.instantReloadProject(projectIndex, false);
				this.instantState.recentAction.push({ projectIndex: projectIndex, action: 'instantReload' });
				// this.setState({ isAnimating: false });
				// this.caseStudy.onCaseStudyExited(true, true);
			}, 800);
			// this.context.isPopping = false;
		} else {
			// console.log('normal reload');
			if (this.instantState.recentAction.length > 2) {
				if (
					this.instantState.recentAction[this.instantState.recentAction.length - 2].projectIndex ==
						projectIndex &&
					this.instantState.recentAction[this.instantState.recentAction.length - 2].action ==
						'instantReload' &&
					this.instantState.recentAction[this.instantState.recentAction.length - 1].projectIndex ==
						projectIndex &&
					this.instantState.recentAction[this.instantState.recentAction.length - 1].action ==
						'instantUnload'
				) {
					this.projects.resetText(projectIndex);
				}
			}
			//
			// 			if (recentAction.projectIndex == projectIndex && recentAction.action == 'instantUnload') {
			// 			}

			this.setState({ isAnimating: true });
			this.projectsTimeline = new TimelineMax({
				onComplete: () => this.setState({ hasAnimated: false, isAnimating: false })
			})
				.add(() => this.projects.reloadProject(projectIndex))
				.add(() => this.home.seekToEnd())
				.add(() => this.updateProjectsOpacity(projectIndex, 0, 0))
				.to({}, 1.3, {}) // need to update timing
				.add(() => this.updateProjectsOpacity(projectIndex, 1, 0.8));

			this.instantState.recentAction.push({ projectIndex: projectIndex, action: 'reload' });
		}
	};

	reunloadProjectToProject = (
		originIndex,
		destinationIndex,
		customReloadDelay,
		customUnloadDelay,
		transitionDelay
	) => {
		if (originIndex > destinationIndex) {
			this.animateTransitionCover(true, transitionDelay);
		} else {
			this.animateTransitionCover(false, transitionDelay);
		}

		setTimeout(() => {
			this.navigationReloadProject(originIndex);
			// @ NOTE
			// Apparently no delay removes it from the current execution queue
			// Making it appear immediately when it can
			// So it works..?
			// If this doesn't go too well in testing
			// adjust the values to 550 ~ 650 ms
			// 50ms too short for people to notice immediately
			setTimeout(() => {
				this.navigationUnloadProject(destinationIndex);
			}, 100 + customUnloadDelay);
		}, 550 + customReloadDelay);
		// 600ms is the exact timing when the full window is covered by transitionCover
	};

	caseStudyReunloadProjectToProject = (originIndex, destinationIndex) => {
		this.reunloadProjectToProject(originIndex, destinationIndex, 0, -50, 0);
		if (this.projects) {
			this.projects.updateProjectsOpacity(destinationIndex, 0, 0);

			setTimeout(() => {
				this.projects.updateProjectsOpacity(destinationIndex, 1, 0);
			}, 600);
		}
	};

	instantUnloadProject = projectIndex => {
		if (!this.state.isAnimating && !this.state.hasAnimated) {
			// console.log('instantUnloadProject', projectIndex);
			this.setState({ isAnimating: true });
			// window.scroll(this.idealProjectScrollPosition[projectIndex], 0);

			this.projectsTimeline = new TimelineMax({
				onComplete: () => this.setState({ hasAnimated: true, isAnimating: false })
			})
				.add(() => this.updateProjectsOpacity(projectIndex, 0, 0))
				.add(() => this.projects.instantUnloadProject(projectIndex))
				.add(
					() =>
						// setTimeout(() => {
						this.home.remove()
					// }, 1000)
				);

			this.instantState.recentAction.push({ projectIndex: projectIndex, action: 'instantUnload' });
		}
	};

	instantReloadProject = (projectIndex, instantReloadText) => {
		if (!this.state.isAnimating && this.state.hasAnimated) {
			// console.log('instant RELOAD Project');
			this.setState({ isAnimating: true });
			// alert('hide case study now');
			this.updateRenderStates('caseStudy', false);

			// I think I can un-timeline-ize all of this
			this.projectsTimeline = new TimelineMax({
				onComplete: () => this.setState({ hasAnimated: false, isAnimating: false })
			})
				.add(() => this.projects.instantReloadProject(projectIndex, instantReloadText))
				.add(() => this.home.seekToEnd())
				// .add(() => console.log('home seek end called and others should be soon'))
				//.add(() => this.updateProjectsOpacity(0, projectIndex, 0))
				//.to({}, 1.3, {}) // need to update timing
				.add(() => this.updateProjectsOpacity(projectIndex, 1, 0))
				// .add(() => this.forceUpdate())
				// Is ForceUpdate necessary?
				.add(() => this.updateRenderStates('caseStudy', true));
		}
	};

	navigationUnloadProject = projectIndex => {
		this.instantUnloadProject(projectIndex);
		// Home remove is called as part of instantUnloadProject
	};

	navigationReloadProject = projectIndex => {
		this.home.onEnterHome(true);
		this.instantReloadProject(projectIndex, true);
		// Force Update to make sure home is rendered before instant Unload
		this.forceUpdate();
	};

	updateProjectIndex = projectIndex => {
		// console.log('updateProjectIndex', projectIndex);
		this.setState({ projectIndex: projectIndex });
	};

	updateTransitionDelay = value => {
		if (value != this.state.transitionDelay) this.setState({ transitionDelay: value });
	};

	clearProjectsGSAPStates = projectIndex => {
		this.projects.clearProjectsGSAPStates(projectIndex);
	};

	animateTransitionCover = (topBottom, customDelay) => {
		if (topBottom) {
			this.transitionCover.animateTopBottom(customDelay);
		} else {
			this.transitionCover.animateBottomTop(customDelay);
		}
	};

	RouteNavigation = ({ location }) => {
		return (
			this.state.renderStates.navigation && (
				<Navigation
					location={location}
					getHistory={this.getHistory}
					updateRenderStates={this.updateRenderStates}
					updateMenuOpacity={this.updateMenuOpacity}
					unloadProject={this.navigationUnloadProject}
					reloadProject={this.navigationReloadProject}
					setInstantState={this.setInstantState}
				/>
			)
		);
	};

	RouteTransitionCover = ({ location }) => {
		return <TransitionCover ref={el => (this.transitionCover = el)} location={location} />;
	};

	RouteIntro = () => {
		return this.state.renderStates.intro && <Intro updateRenderStates={this.updateRenderStates} />;
	};

	RouteHome = ({ location }) => {
		return (
			this.state.renderStates.home && (
				<Home
					ref={el => (this.home = el)}
					location={location}
					pushHistory={this.pushHistory}
					getHistory={this.getHistory}
					hasHistoryInitialized={this.hasHistoryInitialized}
				/>
			)
		);
	};

	RouteProjects = ({ location }) => {
		return (
			this.state.renderStates.projects && (
				<Projects
					ref={el => (this.projects = el)}
					location={location}
					pushHistory={this.pushHistory}
					getHistory={this.getHistory}
					hasHistoryInitialized={this.hasHistoryInitialized}
					playProject={this.playProject}
					instantUnloadProject={this.instantUnloadProject}
					projectIndex={this.state.projectIndex}
					updateProjectIndex={this.updateProjectIndex}
					setInstantState={this.setInstantState}
					getInstantState={this.getInstantState}
				/>
			)
		);
	};

	RouteComingSoon = ({ location }) => {
		return (
			this.state.renderStates.home && (
				<ComingSoon ref={el => (this.comingSoon = el)} location={location} />
			)
		);
	};

	RouteCaseStudy = ({ location }) => {
		return (
			this.state.renderStates.caseStudy &&
			this.state.projectIndex >= 0 && (
				<CaseStudy
					ref={el => (this.caseStudy = el)}
					location={location}
					projectIndex={this.state.projectIndex}
					getHistory={this.getHistory}
					hasHistoryInitialized={this.hasHistoryInitialized}
					setInstantState={this.setInstantState}
					getInstantState={this.getInstantState}
					clearProjectsGSAPStates={this.clearProjectsGSAPStates}
					unloadProject={this.navigationUnloadProject}
					reloadProject={this.navigationReloadProject}
					reunloadProjectToProject={this.caseStudyReunloadProjectToProject}
				/>
			)
		);
	};

	RouteBase = () => {
		return (
			this.state.renderStates.base && (
				<Base ref={el => (this.base = el)} updateRenderStates={this.updateRenderStates} />
			)
		);
	};

	RouteApp = ({ location }) => {
		this.location = location;

		return (
			<>
				<Route path="/" component={this.RouteTransitionCover} />
				<Route path="/" component={this.RouteNavigation} />
				<Route path="/" component={this.RouteIntro} />
				<main className="main">
					<Route
						exact
						path={['/', '/ctflearn', '/italic', '/fountain', '/bca-electives', '/blazescout']}
						component={this.RouteHome}
					/>
					<Route path="/" component={this.RouteProjects} />
					<Route path="/" component={this.RouteCaseStudy} />
					<Route path="/" component={this.RouteComingSoon} />
					{/* <div style={{ flex: 'none', background: 'blue', width: '2000px', height: '100vh' }} /> */}
					{/* <div */}
					{/* 	style={{ flex: 'none', background: 'transparent', width: '2000px', height: '100vh' }} */}
					{/* /> */}
				</main>
				<Route path="/" component={this.RouteBase} />
			</>
		);
	};

	render() {
		if (this.state.onMobile) {
			return <Mobile />;
		}

		return (
			<Router>
				<Route path="/" component={this.RouteApp} />
			</Router>
		);
	}
}

document.addEventListener('DOMContentLoaded', function() {
	const root = document.getElementById('root');
	ReactDOM.render(<Index />, root);
});
